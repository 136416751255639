import { createAsyncThunk } from "@reduxjs/toolkit";
import { createProjectApi } from "../../api/projects";
import { getErrorMessage } from "../../state/errors/errors.epic";
import { CreateProjectData } from "../../state/projects/types";

export const createProject = createAsyncThunk(
  "@projects/createProject",
  async (arg: CreateProjectData, thunkApi) => {
    try {
      const result = await createProjectApi(arg);
      if (result.data) {
        return result.data;
      } else {
        return thunkApi.rejectWithValue(result.error);
      }
    } catch (error: any) {
      return thunkApi.rejectWithValue(getErrorMessage(error));
    }
  }
);
