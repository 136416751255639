import { useForm, SubmitHandler } from "react-hook-form";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { toasterOptions } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { appLog } from "src/debugLoggers";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { organizationSelectors } from "../../../state/organization";
import {
  Project,
  projectsActions,
  projectsSlice,
  type CreateProjectData
} from "../../../state/projects";
import { Input } from "../../forms/Input";
import { Textarea } from "../../forms/Textarea";
import { Modal } from "../../modals/modal";
import { ModalHeader } from "../../modals/modalElements/modal.header";
import { PrimaryButton, TextButton } from "../../shared/button";
import { DEFAULT_DROPDOWN_PICKER_COLORS } from "../../shared/dropdown.color.picker";
import { Spinner } from "../../spinner";

interface CreateProjectModalProps {
  onClose: () => void;
  onCreateProject: (project: Project) => void;
}

interface ProjectDataValues extends Pick<Project, "description" | "name"> {
  purchaseOrderNumber?: string;
}

const { createProject } = projectsSlice;

export const CreateProjectModal = ({
  onClose,
  onCreateProject
}: CreateProjectModalProps) => {
  const dispatch = useAppDispatch();

  const tRoot = useI18n();
  const tProjects = useI18n("PROJECTS.");

  const orgId = useAppSelector(organizationSelectors.uid);

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    reset
  } = useForm<ProjectDataValues>({
    defaultValues: {
      description: "",
      name: "",
      purchaseOrderNumber: ""
    }
  });

  const onSubmit: SubmitHandler<ProjectDataValues> = async values => {
    const now = DateTime.now().startOf("day");
    const data: CreateProjectData = {
      appearanceProperties: {
        color: DEFAULT_DROPDOWN_PICKER_COLORS[0],
        icon: ""
      },
      customerProperties: {
        purchaseOrderNumber: values.purchaseOrderNumber
      },
      description: values.description,
      endDate: now.plus({ years: 1 }).toISODate(),
      maxProjectUsers: -1,
      maxProjectWorkspaces: -1,
      name: values.name,
      ownerId: orgId,
      ownerType: "Org",
      startDate: now.toISODate()
    };

    try {
      appLog("create project", { data });
      const createProjectPromise = dispatch(createProject(data)).unwrap();
      const result = await toast.promise(
        createProjectPromise,
        {
          pending: tProjects("CREATE_NEW_PROJECT_TOAST_PENDING"),
          success: tProjects("CREATE_NEW_PROJECT_TOAST_SUCCESS"),
          error: tProjects("CREATE_NEW_PROJECT_TOAST_FAILURE")
        },
        { ...toasterOptions }
      );
      appLog("create project success", result);
      dispatch(projectsActions.getMyProjectsAsync.request());
      onDismiss();
      onCreateProject(result);
    } catch (error) {
      appLog("failed to create project", JSON.stringify(error));
    }
  };

  const onDismiss = () => {
    reset();
    onClose();
  };

  return (
    <Modal handleOutsideClick={onDismiss}>
      <ModalHeader
        modalTitle={tProjects("CREATE_NEW_PROJECT_TITLE")}
        closeModal={onDismiss}
      />
      <form
        className="flex flex-col gap-4 w-full sm:w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-2">
          {isSubmitting && (
            <div className="absolute inset-0 flex justify-center items-center z-10 backdrop-brightness-[0.95]">
              <Spinner />
            </div>
          )}
          <Input
            aria-invalid={!!errors.name}
            autoFocus
            error={errors.name?.message}
            label={tProjects("NEW_PROJECT_TITLE")}
            style={{ width: "100%" }}
            type="text"
            {...register("name", {
              required: tProjects("ERROR_EMPTY_PROJECT_TITLE")
            })}
          />
          <Textarea
            label={tProjects("DESCRIPTION")}
            {...register("description")}
            rows={6}
          />
          <Input
            aria-invalid={!!errors.purchaseOrderNumber}
            error={errors.purchaseOrderNumber?.message}
            label={tProjects("NEW_PROJECT_PURCHASE_ORDER_NUMBER")}
            style={{ width: "100%" }}
            type="text"
            {...register("purchaseOrderNumber")}
          />
        </div>
        <div className="text-right">
          <TextButton onClick={onDismiss}>{tRoot("CANCEL")}</TextButton>
          <PrimaryButton type="submit" disabled={!isValid || isSubmitting}>
            {tProjects("CREATE")}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};
