import { useContext } from "react";
import { AppContext } from "../../../context";
import { ProjectsList } from "../../projects.list/projects.list";
import { Tile } from "../../tile";
import { Header } from "../header";

export const Projects = () => {
  const context = useContext(AppContext);

  return (
    <div>
      <Header title={context.Localized.string("PROJECTS.LIST_TITLE")} />
      <Tile className="flex-grow">
        <ProjectsList />
      </Tile>
    </div>
  );
};
