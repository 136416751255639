import React, { forwardRef } from "react";
import { useId } from "@/hooks";
import { ControlLabel } from "./ControlLabel";
import { ControlWrapper } from "./ControlWrapper";

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: string;
  label: string;
  inputStyle?: React.CSSProperties;
  resize?: boolean;
  toolTip?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ id: idValue, toolTip, style, inputStyle, resize, ...props }, ref) => {
    const { label, error, disabled, value } = props;
    const id = useId(idValue);
    const displayLabel = error ?? label;

    return (
      <ControlWrapper disabled={disabled} error={error} style={style}>
        <ControlLabel error={!!error} htmlFor={id}>
          {displayLabel}
        </ControlLabel>
        <textarea
          ref={ref}
          data-test-id="test-textarea"
          id={id}
          aria-label={displayLabel}
          className={`w-full ${
            resize ? "" : "resize-none"
          } text-sm border-none outline-none break-words overflow-x-hidden overflow-y-auto input customScrollbar bg-transparent`}
          style={inputStyle}
          title={error ?? toolTip ?? String(value ?? "")}
          {...props}
        />
      </ControlWrapper>
    );
  }
);
