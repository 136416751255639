import { generatePath } from "react-router-dom";
import { MyWorkspaceMetadataV3, ResponsePromise } from "./types";
import type {
  Collaborator,
  CreateProjectData,
  Project,
  UserMapping
} from "../state/projects/types";
import handleFetchRequest from "./handle-fetch-request";
import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import config from "../configuration";

export function getAllOrgProjects(orgId: string): ResponsePromise<Project[]> {
  return handleFetchRequest<Project[]>(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
      }/api/v1/projects/orgs/${encodeURIComponent(orgId)}`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export async function getMyProjects(): ResponsePromise<Project[]> {
  return handleFetchRequest<Project[]>(() =>
    fetch(`${config["serviceConfig"].documentMetadata}/api/v1/projects/my`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export async function getCollaborators(
  projectId: string
): ResponsePromise<Collaborator[]> {
  return await handleFetchRequest<Collaborator[]>(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
      }/api/v1/projects/${encodeURIComponent(projectId)}/users`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export async function getWorkspacesByProject(
  projectId: string
): ResponsePromise<MyWorkspaceMetadataV3[]> {
  return await handleFetchRequest<MyWorkspaceMetadataV3[]>(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
      }/api/v3/projects/${encodeURIComponent(projectId)}/workspaces`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

/**
 * Add user with specific role to a project
 * Errors:
 * 400: response body is `ErrorResponse<"ProjectNotFound"|"UserNotFound"|"RoleNotFound"|"Other">`
 * 400: maxProjectUsers
 * 404: Not found (endpoint not found)
 */
export function addUserToProject(
  projectId: string,
  user: { userId?: string; userEmail?: string },
  roleId: string
): ResponsePromise<UserMapping> {
  // TODO: if user.userEmail and user.userId are undefined, throw an error?
  const encodedParams = user.userId
    ? new URLSearchParams({
        userId: user.userId,
        roleId: roleId
      }).toString()
    : new URLSearchParams({
        userEmail: user.userEmail ?? "",
        roleId: roleId
      }).toString();
  return handleFetchRequest<UserMapping>(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
      }/api/v1/projects/${encodeURIComponent(
        projectId
      )}/users?${encodedParams}`,
      {
        method: httpMethods.PUT,
        headers: globalHeaders
      }
    )
  );
}

/**
 * Update users role on a project. Note: Also would add that user, since same endpoint as add - but this should encourage the use of userId, which is much cheaper to use on the service
 * Errors:
 * 400: response body is `ErrorResponse<"ProjectNotFound"|"UserNotFound"|"RoleNotFound"|"Other">`
 * 400: also when over maxProjectUsers
 * 404: Not found (endpoint not found)
 */
export function updateUserRoleOnProject(
  projectId: string,
  userId: string,
  roleId: string
): ResponsePromise<UserMapping> {
  return addUserToProject(projectId, { userId }, roleId);
}

/**
 * Delete list of users from project by list of userIds
 */
export function deleteUsersFromProject(
  projectId: string,
  usersIds: string[]
): ResponsePromise {
  return handleFetchRequest(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
      }/api/v1/projects/${encodeURIComponent(projectId)}/users`,
      {
        method: httpMethods.DELETE,
        headers: globalHeaders,
        body: JSON.stringify(usersIds)
      }
    )
  );
}

export function updateProject(
  updates: Partial<Project>,
  projectId: string
): ResponsePromise<Project> {
  return handleFetchRequest<Project>(() =>
    fetch(
      `${
        config["serviceConfig"].documentMetadata
        // @ts-ignore
      }/api/v1/projects/${encodeURIComponent(projectId)}`,
      {
        method: httpMethods.PUT,
        headers: globalHeaders,
        body: JSON.stringify(updates)
      }
    )
  );
}

export const createProjectApi = (createProjectData: CreateProjectData) =>
  handleFetchRequest<Project>(() =>
    fetch(
      generatePath(":service/api/:version/projects", {
        service: config["serviceConfig"].documentMetadata,
        version: "v3"
      }),
      {
        method: httpMethods.POST,
        headers: globalHeaders,
        body: JSON.stringify(createProjectData)
      }
    )
  );
