import {
  configureStore,
  PreloadedStateShapeFromReducersMapObject
} from "@reduxjs/toolkit";
import rootReducer from "./root.reducer";
import { epicMiddleware } from "./middlewares";

export type PreloadedState = PreloadedStateShapeFromReducersMapObject<
  typeof rootReducer
>;

export default function createStore(preloadedState: PreloadedState = {}) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(epicMiddleware)
  });
}

export type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];
