import React, { forwardRef } from "react";
import { useId } from "@/hooks";
import { ControlLabel } from "./ControlLabel";
import { ControlWrapper } from "./ControlWrapper";

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  label: string;
  inputStyle?: React.CSSProperties;
  toolTip?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ id: idValue, toolTip, style, inputStyle, ...props }, ref) => {
    const { label, error, disabled, value } = props;
    const id = useId(idValue);
    const displayLabel = error ?? label;

    return (
      <ControlWrapper disabled={disabled} error={error} style={style}>
        <ControlLabel error={!!error} htmlFor={id}>
          {displayLabel}
        </ControlLabel>
        <input
          ref={ref}
          data-test-id="test-input"
          id={id}
          aria-label={displayLabel}
          className="bg-color-transparent text-sm border-none outline-none w-full"
          style={inputStyle}
          title={error ?? toolTip ?? String(value ?? "")}
          {...props}
        />
      </ControlWrapper>
    );
  }
);
