import { CSSProperties, PropsWithChildren } from "react";

interface ControlWrapperProps {
  disabled?: boolean;
  error?: string;
  style?: CSSProperties;
}

export const ControlWrapper = ({
  children,
  disabled,
  error,
  style
}: PropsWithChildren<ControlWrapperProps>) => (
  <div
    className={`p-1 border-b border-solid border-${
      error ? "colorWarning" : "white"
    } rounded-lg focus-within:ring focus-within:ring-inset bg-${
      disabled ? "colorForegroundLightTheme" : "white"
    }`}
    style={style}
  >
    {children}
  </div>
);
