import { PropsWithChildren } from "react";

export interface ControlLabelProps
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  error?: boolean;
}

export const ControlLabel = ({
  children,
  error,
  ...props
}: PropsWithChildren<ControlLabelProps>) => {
  return (
    <label
      {...props}
      className={`text-${
        error ? "colorWarning" : "colorPrimaryLightTheme"
      } block text-xxs`}
    >
      {children}
    </label>
  );
};
