export const English: any = {
  CLOSE: "Close",
  NOT_ADMIN:
    "You need to be an administrator in your organization to use Management Portal",
  CANCEL: "Cancel",
  COLOR: "Color",
  DROPDOWN: {
    ALL_ITEMS_SELECTED: "All items are selected.",
    CLEAR_SEARCH: "Clear Search",
    NO_OPTIONS: "No options",
    SEARCH: "Search",
    SELECT_ALL: "Select All",
    CLEAR_ALL: "Clear All",
    CUSTOM_OPTION_PLACEHOLDER: "Enter your option",
    OTHER: "Other"
  },
  SAVE: "Save",
  OK: "OK",
  ACCEPT_AND_CONTINUE: "Accept and Continue",
  CONFIRM: "Confirm",
  DELETE: "Delete",
  RESET: "Reset",
  REMOVE_COLLABORATOR: "Remove Collaborator",
  ICON: "Icon",
  UPDATE: "Update",
  CONTACT_US: "Contact us",
  SEARCH: "Search",
  HEADER: {
    TITLE: "Hoylu Management Portal",
    FEATURE: "Features",
    PRICING: "Pricing"
  },
  USER_PANEL: {
    LOGOUT: "Logout",
    MY_PROFILE: "My Profile",
    ORGANIZATION: "Organization"
  },
  FOOTER: {
    CONTENT: "© ${0} All Rights Reserved. Hoylu Management Portal powered by "
  },
  LOGIN: {
    HEADER: "Login to Hoylu Management Portal",
    LOGIN: "Login",
    CONTACT: "Contact Hoylu Support for Administrator access"
  },
  NAVBAR: {
    ACCOUNTS: "Users",
    POLICIES: "Policies",
    INFO: "Company Information",
    AD_MANAGEMENT: "Azure AD Administration",
    INTEGRATIONS: "Integrations",
    PROJECTS: "Projects",
    WORKSPACES: "Workspaces",
    PROFILE: "Profile",
    BILLING: "Billing"
  },
  ACCOUNTS_LIST: {
    TITLE: "Current Users",
    NAME: "Name",
    EMAIL: "Email",
    DEPARTMENT: "Department",
    LICENSE_EXPIRATION: "License Expiration",
    LAST_SEEN: "Last Seen",
    LICENSES: "Licenses",
    LICENSE_NONE: "none",
    LAST_ACCESSED_NEVER: "never",
    EDIT: "Edit",
    REMOVE_ACCOUNTS: "Remove Users",
    REMOVE_CONFIRM: "Do you really want to remove ${0} user(s)?",
    FILTER_PLACEHOLDER: "Filter by name, email or department...",
    EXPORT_ACCOUNTS: "Export Users",
    ADD_ACCOUNTS: "Add Users",
    SORT_BY: "Sort by ${0}",
    ADMIN_REMOVAL_INFO:
      "At least one user chosen for removal is an administrator. It is not allowed to remove such users in the Management Portal.",
    OTHER_REMOVAL_INFO: "Other selected users will be removed.",
    CONTACT_SUPPORT: "Please, contact support to do that",
    PAGINATION: {
      PAGE_NUMBER: "Page",
      OF_TOTAL_PAGES: "of ${0}",
      SHOW_NUMBER_PAGES: "Show ${0}"
    },
    EXPORT: {
      EMAIL: "User",
      FIRST_NAME: "First Name",
      LAST_NAME: "Last Name",
      DEPARTMENT: "Department",
      EXPIRATION: "Expiration Date",
      LAST_ACCESS: "Last Access"
    },
    EDIT_ACCOUNT: {
      ADD_PROJECT_ACCESS: "Add Project Access",
      APPLICATION_LICENSES: "Application Licenses",
      APPLICATION_ACCESS: "Application Access",
      ASSIGN_HOYLU_LICENSE: "Assign Hoylu License",
      TITLE: "License Management",
      FAILED_TO_LOAD: "Failed to load licenses.",
      LICENSE: "License",
      LICENSE_NONE_REMOVE: "None (Remove)",
      LICENSE_VALID_UNTIL_DATE: "Valid until ${0}",
      PERSONAL: "Personal",
      PROJECTS_IN_ORGANIZATION: "Projects in organization",
      EXPIRED: "Expired",
      USER_PROJECTS: "User projects"
    }
  },
  PERMISSIONS: {
    ACCESS: "access",
    ADMIN: "admin",
    ANY_PROJECT_ADMIN: "Any project admin",
    BASIC: "basic",
    BY_POLICY_ANY_PROJECT_ADMIN: "By policy any project admin",
    HAS: "has",
    UNLOCKED: "Unlocked",
    LOCKED: "Locked",
    USERS: "Users"
  },
  POLICIES: {
    TITLE: "Workspace Permission",
    DEFAULT_ACCESS_INFO: "All workspaces are created private by default.",
    disallow_external_read_title:
      "Workspaces can only be accessed by company accounts",
    disallow_external_read:
      "When selected, this policy applies to all shared workspaces that are (and were) created by user accounts that are members of the organization. Authenticated accounts that are outside of the organization will have no access to organization workspaces. There is no guest (unauthenticated) access to organization workspaces.",
    disallow_external_write_title:
      "Workspaces can only be edited by company accounts",
    disallow_external_write:
      "When selected, this policy applies to all shared workspaces that are (and were) created by user accounts that are members of the organization. Authenticated accounts that are outside of the organization will have read-only access to the workspaces. Guest (unauthenticated) read-only access to workspaces is also enabled.",
    documents_require_password_title: "Require passwords for all workspaces",
    documents_require_password:
      "When selected, this policy applies to all workspaces that are newly created and shared by user accounts that are within the organization. Existing shared workspaces will not be required to set a password. The only restriction on password quality is that the password must have a minimum of 8 characters.",
    allow_explicit_external_access:
      "Allow workspaces to be shared with external users after company policy is acknowledged.",
    only_org_admins_can_create_workspace_title:
      "Workspaces can only be created by organization administrator accounts",
    only_org_admins_can_create_workspace:
      "When selected, this policy applies to all user accounts that are members of the organization. Only user accounts with organization administrator role are able to created new workspaces.",
    all_org_admins_get_admin_access_title:
      "Workspace admin permissions to all organization administrators",
    all_org_admins_get_admin_access:
      "When selected, this policy applies to all workspaces that are (and were) created by user accounts that are members of the organization. User accounts with organization administrator role are granted admin permissions to all workspaces.",
    enforce_two_factor_login_title:
      "Enforce two-factor authentication (2FA) for basic login users",
    enforce_two_factor_login:
      "When selected, this policy applies to all basic user accounts that are members of the organization. Users will have to verify their identity with two-factor authentication via an authenticator app or email in order to successfully log in."
  },
  TOTAL_ACCOUNTS: {
    HEAD: "Total Users",
    LICENSED: "Licensed",
    UNLICENSED: "Unlicensed"
  },
  LICENSES: {
    HEAD: "Available Licenses",
    EXPIRE: "Expiration Date",
    ENTERPRISE: "Enterprise",
    QTY: "No. of Licenses",
    AUTO_ASSIGN: "Auto-assign licenses to users when they log-in",
    AGREEMENT: "Company agreement valid until ${0}",
    PLUS_PLAN: "Plus plan"
  },
  ACCOUNTS: {
    TITLE: "Users and Licenses"
  },
  ADD_ACCOUNTS: {
    TITLE: "Add Users",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    EMAIL: "Email",
    DEPARTMENT: "Department",
    ADD_ACCOUNTS: "Add Users",
    DROP_AREA: "New Users",
    DROP_AREA_WATERMARK:
      "Enter comma separated list of emails or drop valid CSV file.",
    IMPORT: "Import",
    REGISTERED_ALREADY:
      "The following existing user(s) will be invited to join your organization.",
    CREATE: "Create User Accounts",
    EMAIL_CORRECTION: {
      ERROR: "Provided text doesn't match email pattern.",
      CONTINUE: "Continue"
    },
    END_MESSAGE_1:
      "Invitation(s) to join your organization have been sent. An invitation is valid for 7 days, after which if the invitation is not accepted, it will be considered to be declined.",
    END_MESSAGE_2:
      "Previously existing Hoylu user accounts will not be visible to your organization until the invitation is accepted. New users that were created through your invitation will be visible under the Users List in the Management Portal."
  },
  ERRORS: {
    LOGGED_OUT: "You were logged out. Please log in",
    GET_AVAILABLE_ERROR: "Failed to load licenses",
    CREATE_LICENSES_ERROR: "There was an error while creating licence(s)",
    EXTEND_LICENSE_ERROR: "There was an error while extending licence(s)",
    SET_LICENSE_TO_USER_ERROR:
      "There was an error while adding licence to user",
    TAKE_LICENSE_FROM_USER_ERROR:
      "There was an error while removing licence from user",
    GET_GROUPS_ERROR: "Failed to load groups",
    UPDATE_GROUP_LICENSE_ERROR: "There was an error while updating group",
    INVITE_ADMIN_ERROR: "Failed to send invitation",
    GET_ORGANIZATION_INFO_ERROR: "Failed to load organization information",
    GET_ORGANIZATION_POLICIES_ERROR: "Failed to load policies",
    SAVE_ORGANIZATION_POLICIES_ERROR:
      "There was an error while saving policies",
    SAVE_ORGANIZATION_DEPARTMENTS_ERROR:
      "There was an error while adding department",
    EDIT_MEMBER_DEPARTMENT_ERROR:
      "There was an error while changing department",
    REMOVE_MEMBERS_ERROR: "There was an error while removing the user",
    LOGIN_ERROR: "Failed to log in",
    CONNECTIONS_ERROR: "Failed to fetch Jira connections",
    GET_LICENSE_INFO_ERROR: "Failed to fetch license info",
    GET_ALL_ORG_PROJECTS_ERROR: "Failed to fetch organization projects",
    GET_MY_PROJECTS_ERROR: "Failed to fetch projects",
    GET_COLLABORATORS_ERROR: "Failed to fetch collaborators",
    UPDATE_PROJECT_ERROR: "Failed to update project",
    GET_WORKSPACES_IDS_ERROR: "Failed to fetch workspaces",
    GET_WORKSPACES_DETAILS_ERROR: "Failed to fetch workspaces",
    GET_USER_PROFILE_ERROR: "Failed to fetch user profile",
    CHANGE_USER_SETTINGS: "Failed to save user settings",
    LOAD_USER_SETTINGS: "Wasn't able to fetch user settings"
  },
  AD_MANAGEMENT: {
    REQUEST_HEADER: "Request Azure Active Directory Login Enablement",
    REQUEST_TEXT:
      "Enter the email address of your organization’s Azure Active Directory administrator to enable Azure Active Directory login access for Hoylu.",
    ENTER_EMAIL: "Enter administrator email",
    SUBMIT: "Submit",
    REQUEST_SEND_HEADER: "Request Sent",
    REQUEST_SEND_P1:
      "An email has been sent to your organization's Azure Active Directory (AAD) administrator from ${email}. This email contains two separate permissions requests: one to enable single sign-on for your organization, and one to enable licensing of any AAD group within your organization. Both permission links must be accepted by the Azure Active Directory (AAD) administrator for your Hoylu software to enable single sign-on.",
    REQUEST_SEND_P2:
      "You will receive an email from ${email} as each permission request is accepted. After both permission requests have been accepted, you will need to sign-in to the Management Portal with your Azure credentials."
  },
  GROUP_MANAGEMENT: {
    HEADER: "Azure AD Administration",
    ACTIVE_GROUPS_TITLE: "Azure AD Groups Licensed for Hoylu",
    MORE_PAGES_AVAILABLE:
      "The groups shown below are only a subset of the available groups that exist in this organization's Active Directory.  If the group you are looking for is not in the list, enter the start of the group name in the box below and press the Refine button.",
    NONACTIVE_GROUPS_TITLE: "Available Azure AD Groups",
    REFINE_GROUPS_BUTTON: "Refine",
    ACTIVATE: "Activate",
    DEACTIVATE: "Deactivate"
  },
  COMPANY_INFORMATION: {
    TITLE: "Company Information",
    COMPANY_NAME: "Company Name",
    DOMAINS: "Company Domain Names",
    ADMINS: "Company Admin Emails",
    DEPARTMENTS: "Departments",
    ADD_DEPARTMENTS: "Add New Departments",
    ADD_DEPARTMENTS_PLACEHOLDER: "Enter department(s), separated by commas...",
    SAVE_DEPARTMENTS: "Save Departments"
  },
  consent: {
    "not-given": "Consent Not Given",
    "not-given-sub":
      "People in your organization will not be able to sign in to Hoylu with Active Directory until consent is granted.",
    given: "Access granted",
    "given-sub-progress":
      "You have consented for ${0}.  Please note that your consent for ${1} is still required to complete this process.",
    "given-sub-final":
      "You have consented for ${0}. No further action is required.",
    "server-description":
      "Hoylu to query group membership on behalf of a logged in user",
    "management-description":
      "Hoylu to read Azure Active Directory on behalf of the logged in Hoylu administrator"
  },
  INTEGRATIONS: {
    EULA: "By checking this box and clicking Accept, Hoylu is allowed to access and exchange Customer Data with ${0} on your behalf",
    SET_TITLE: "Set Up ${0} Integration",
    CONNECTION_NAME_PLACEHOLDER: "Enter a Name for this ${0} Integration",
    CONNECTION_NAME: "Connection name",
    DELETE_SUCCESS: "Config is deleted!",
    DELETE_ERROR: "Config was not deleted",
    REMOVE_CONFIRM: "Do you really want to remove ${0} integration?",
    REMOVE_INTEGRATION: "Remove Integration",
    UPDATE_INTEGRATION: "Update ${0} Integration: ${1}",
    URL: "${0} URL",
    URL_ERROR: "Please, provide valid url with 'https://' protocol",
    TOKEN: "${0} User API token (secret)",
    TOKEN_PLACEHOLDER: "Paste API token for ${0} Bot here",
    TEST_SUCCESS: "Connection test succeeded, config is being saved.",
    TEST_ERROR: "Connection test failed, config was not saved",
    SAVE_SUCCESS: "Config is saved!",
    INTEGRATION_CONNECTION: "${0} Connection"
  },
  JIRA: {
    URL: "Jira Url",
    URL_PLACEHOLDER: "Enter the URL to the Jira ${0} (https://*)",
    USER: "Jira User Id",
    USER_PLACEHOLDER:
      "${0} of the Jira user used for Jira Bot - requires read access to Jira",
    USER_ERROR: "Please, use valid email",
    SELECT_TYPE: "Select Jira type",
    SELECT_TYPE_FOR: "Select Jira type for ${0} instance",
    server: "Data Center or Server",
    cloud: "Cloud",
    OAUTH_VERSION: "OAuth version",
    OAuth1: "OAuth 1.0 (Jira 8)",
    OAuth2: "OAuth 2.0 (requires Jira 9 or newer)",
    PASSWORD: "Jira User password",
    PASSWORD_PLACEHOLDER: "Paste user password for Jira Bot here",
    TYPE: "Server Type",
    CLIENT_ID: "Client ID",
    CLIENT_ID_PLACEHOLDER:
      "Client ID of Incoming External Application Link in Jira",
    CLIENT_SECRET: "Client Secret",
    CLIENT_SECRET_PLACEHOLDER:
      "Client Secret of Incoming External Application Link in Jira"
  },
  AGILITY: {
    URL_PLACEHOLDER: "Enter the URL to the Agility (https://*)"
  },
  UPSELL: {
    INFO: "This feature is available with the Enterprise Plan.",
    CONTACT: "Contact us to learn more."
  },
  SHARED: {
    COLLAPSE: "Collapse",
    EXPAND: "Expand"
  },
  SHARE_WORKSPACE: {
    COPY_EMAIL_LINK: {
      COPIED_LINK: "Copied Link",
      COPY_LINK: "Copy Link",
      COPY_WORKSPACE_LINK: "Copy Workspace Link",
      EMAIL: "Email Link",
      EMAIL_WORKSPACE_LINK: "Email Workspace Link",
      LEARN_MORE:
        "To learn more about Hoylu and create an account, please visit Hoylu.com.",
      SMALL_COPIED_LINK: "Copied",
      SMALL_COPY_LINK: "Copy",
      SMALL_EMAIL: "Email",
      WORKSPACE_SHARED_WITH_YOU: "A Hoylu Workspace has been shared with you."
    },
    DIALOG: {
      ANYONE_WITH_LINK: "Anyone with this link",
      LINK_SHARING: "Link Sharing",
      PERMISSIONS: "Permissions",
      SHARE_THIS_WORKSPACE: "Share this workspace"
    },
    ERROR: {
      CANNOT_APPLY_CHANGES:
        "We cannot apply your changes to the workspace at this time. Please try again later.",
      CANNOT_CHANGE_PASSWORD:
        "We cannot change password for this workspace. Please try again later.",
      CANNOT_REMOVE_PASSWORD:
        "We cannot remove password for this workspace. Please try again later.",
      CANNOT_SET_PASSWORD:
        "We cannot set password for this workspace. Please try again later."
    },
    PASSWORD_INPUT: {
      TOGGLE_PASSWORD: "toggle password",
      TOGGLE_PASSWORD_VISIBILITY: "Toggle password visibility"
    },
    PERMISSION_BAR: {
      ACCESS: "access",
      ANYONE_WITH_LINK_IN_ORG: "Anyone in my organisation with this link",
      AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS:
        "Authenticated accounts that are outside of the organization will have read-only access to the workspaces.\n" +
        "Guest (unauthenticated) read-only access to workspaces is also enabled.",
      AUTHENTICATED_ACCOUNTS_NO_ACCESS:
        "Authenticated accounts that are outside of the organization will have no access to organization workspaces.\n" +
        "There is no guest (unauthenticated) access to organization workspaces.",
      HAS: "has",
      ONLY_ADMINS_CHANGE_ACCESS:
        "Only workspace admins can change access permissions",
      REMOVE_USER: "Remove User"
    },
    SHARING_ICONS: {
      COLLAPSE: "Collapse",
      EXPAND: "Expand",
      LOCKED: "Locked",
      PERMISSIONS: "Permissions",
      UNLOCKED: "Unlocked",
      USERS: "Users"
    },
    SHARE_TYPE: {
      ADMIN: "admin",
      EDIT: "edit",
      NO_ACCESS: "no",
      READ: "read",
      REMOVE: "remove"
    },
    WORKSPACE_PASSWORD: {
      ADD_PASSWORD: "Add Password",
      ADD_WORKSPACE_PASSWORD: "Add workspace password:",
      CANCEL: "Cancel",
      CHANGE: "Change",
      CHANGE_PASSWORD: "Change password",
      CHANGE_WORKSPACE_PASSWORD: "Change workspace password",
      CHARACTERS: "(at least 8 characters)",
      CONFIRM_NEW_PASSWORD: "Confirm workspace password",
      ENTER_CURRENT_PASSWORD: "Enter current password",
      REMOVE: "Remove",
      REMOVE_PASSWORD: "Remove Password",
      REMOVE_WORKSPACE_PASSWORD: "Remove workspace password",
      SET_NEW_PASSWORD: "Set workspace password",
      WORKSPACE_NO_PASSWORD: "No password set",
      WORKSPACE_PASSWORD_SET: "Workspace password is set"
    },
    WORKSPACE_PERMISSIONS: {
      ACCESS: "access",
      ADD_USERS: "Add Users",
      ADD_WITH: "Add with",
      CANCEL: "Cancel",
      NOTIFY_USERS: "Notify users via e-mail",
      USER_PERMISSION: "User permission will be",
      BY_POLICY_ANY_ORG_ADMIN: "By policy any org admin" // By policy any org admin [has] [Admin, Write, Read, No] [access]
    }
  },
  PROJECTS: {
    TITLE: "Projects Management",
    LIST_TITLE: "List of Projects",
    NAME: "Name",
    ICONS: "Icons",
    COLORS: "Colors",
    STATUS: "Status",
    START: "Start Date",
    END: "End Date",
    COLLABORATORS: "Collaborators",
    CREATE_NEW_PROJECT: "New project",
    CREATE_NEW_PROJECT_TITLE: "Create new Project",
    CREATE: "Create",
    NEW_PROJECT_TITLE: "Title",
    ERROR_EMPTY_PROJECT_TITLE: "Title can't be empty",
    NEW_PROJECT_PURCHASE_ORDER_NUMBER: "Purchase Order Number",
    CREATE_NEW_PROJECT_TOAST_PENDING: "Creating Project",
    CREATE_NEW_PROJECT_TOAST_SUCCESS: "Project Created!",
    CREATE_NEW_PROJECT_TOAST_FAILURE: "Failed to create Project",
    DURATION: "Duration",
    DESCRIPTION: "Description",
    FILTER: "Filter by:",
    EDITOR_TITLE: "Project Settings",
    NOT_STARTED: "Not started",
    NA: "n/a",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    RESET: "Reset filters",
    ADD_USERS_TITLE: "Add Users to Project",
    ADD_USERS: "Add Users",
    ADD_USERS_HINT:
      "Enter E-mail addresses separated by commas or paste text containing E-mail addresses",
    SEARCH_PLACEHOLDER: "Search by name or description",
    REMOVE_USERS: "Remove users",
    EXPORT_DATA: "Export data",
    EXTERNAL_ORG: "External Org:",
    NO_ACCOUNTS_ERROR: "There are no Hoylu accounts for the email(s):",
    USERS_LIMIT: "Users limit reached",
    ROLE_CHANGE_ERROR:
      "There was a problem while trying to update collaborator's role",
    REMOVE_USERS_ERROR: "Failed to remove users",
    USER_COUNT: "Project User Count / Limit",
    WORKSPACES_COUNT: "Project Workspace Count / Limit",
    WORKSPACES_COUNT_ERROR:
      "Couldn't check the amount of workspaces in the project",
    ANY_ADMIN: "Any org admin",
    INVITE_AND_ADD: "Invite to Hoylu",
    // Note: This is sent in the invite email after the line "You have been invited to create a user account at Hoylu by {{inviter}}."
    INVITE_REASON: "Get access to Hoylu Project ${0}",
    GENERIC_USER_ADD_ERROR: "Failed to add user(s)",
    YOU_ARE_NOT_THE_PROJECT_ADMIN: "You are not the project admin",
    FAILED_FETCHING:
      "Requested project (id: ${0}) not found or you do not have permissions",
    ADMIN_ACCESS: "Admin access",
    BASIC_ACCESS: "Basic access"
  },
  WORKSPACES: {
    TITLE: "Workspaces report",
    LIST_TITLE: "List of workspaces",
    EXPORT: "Export Data",
    NAME: "Name",
    CREATED: "Created",
    LINK: "Link Access",
    LABELS: "Labels",
    ADMINS: "Admins",
    SHARE: "Share",
    LAST_ACCESSED: "Last Accessed",
    LAST_EDITED: "Last Edited",
    READ: "read",
    EDIT: "edit",
    NO: "no",
    PASSWORD: "Password",
    ID: "ID",
    TYPE: "Type",
    CREATOR: "Creator",
    HAS_PASSWORD: "Has password",
    PUBLIC_ACCESS: "Public access",
    OTHER_ADMINS: "Other admins",
    READ_ACCESS: "Read access",
    WRITE_ACCESS: "Write access",
    NO_ACCESS: "No access",
    MODULE: "Module",
    PAGE_FETCH_ERROR: "Couldn't fetch page"
  },
  AVATAR: {
    USER: "User",
    RENEWAL: "Renewal Date:",
    PROJECTS: "Projects"
  },
  BILLING: {
    PLAN_DETAILS: "Plan Details",
    SUBSCRIPTIONS: {
      MY_SUBSCRIPTIONS: "My Subscriptions",
      PLAN_NAME: "Plan Name",
      STATUS: "Status",
      PURCHASED: "Purchased",
      RENEWAL_DATE: "Renewal date",
      MANAGED_BY: "Managed by",
      NO_SUBSCRIPTIONS_TO_MANAGE: "No subscriptions to manage.",
      MANAGE: "Manage",
      PROCESSING_TIME_NOTICE:
        "After buying a subscription, wait a few minutes for processing. Don't forget to refresh the page to see your subscription. Thanks!",
      STATUSES: {
        active: "Active",
        canceled: "Canceled",
        incomplete: "Incomplete",
        incomplete_expired: "Incomplete Expired",
        past_due: "Past Due",
        paused: "Paused",
        trialing: "Trialing",
        unpaid: "Unpaid"
      }
    },
    ORGANIZATION: {
      YOUR_HOYLU_ACCESS_IS_MANAGED_BY: "Your Hoylu access is managed by ${0}."
    },
    PLANS: {
      TITLE: "Plans",
      ENTERPRISE: {
        TITLE: "Enterprise",
        DESCRIPTION: "Unlimited access for an entire company",
        MANAGEMENT_PORTAL: "Management Portal",
        SSO_OKTA_AZURE_AD: "SSO (Okta, Azure AD)",
        USER_DOMAIN: "User Domain",
        DEDICATED_CUSTOMER_SUCCESS_MANAGER: "Dedicated Customer Success Manager"
      },
      ADD_PROJECT: {
        TITLE: "Add Project",
        DESCRIPTION: "Easy access and management for an entire project team",
        UNLIMITED_WORKSPACES: "Unlimited workspaces",
        UNLIMITED_PROJECT_MEMBERS: "Unlimited project members",
        PREDEFINED_TIME_PERIOD: "Predefined time period"
      },
      PRODUCTS: {
        usd: "USD",
        month: "month",
        year: "year"
      },
      LEARN_MORE: "Learn more...",
      CONTACT_SALES: "Contact Sales",
      UPGRADE: "Upgrade"
    }
  },
  PROFILE: {
    USER_PROFILE: "User Profile",
    DETAILS: "Details",
    FIRST_NAME: "First Name:",
    LAST_NAME: "Last Name:",
    EMAIL: "Email:",
    COMPANY: "Company:",
    DEPARTMENT: "Department:",
    ORGANIZATION: "Organization:",
    DELETE_ACCOUNT: "Delete Account...",
    AUTHENTICATION_FAILED: "Authentication failed",
    SETTINGS: "Settings",
    EMAIL_NOTIFICATION: "Email Notification Settings",
    LANGUAGE: "Language Preference",
    SETTINGS_SECTION_1: "UI and Notifications",
    SETTINGS_SECTION_2: "Workspace Template and Training Recommendations",
    INDUSTRY: "Industry",
    ROLE: "Role",
    DROPDOWN_PLACEHOLDER: "Select"
  },
  CONFIRM_ACCOUNT_DELETE: {
    TITLE: "Delete Account: ",
    P1: "In order to delete your account:",
    POINT1: "Check the confirmation checkbox and",
    POINT2: 'Then click "Delete Account".',
    P2: "You will receive an e-mail confirming that Hoylu has received your request and a follow up e-mail with further information.\nHoylu will proceed with permanently deleting your account in 7 days.",
    CONFIRM: "Confirm that deleted accounts cannot be recovered",
    DELETE: "Delete Account",
    CANCEL: "Cancel"
  },
  LINKS: {
    PROJECTS: "https://www.hoylu.com/projects/",
    PLANS: "https://www.hoylu.com/plans/",
    CONTACT: "https://www.hoylu.com/contact",
    PRICING: "https://www.hoylu.com/pricing/",
    SALES: "sales@hoylu.com",
    ADMIN_SUPPORT: "admin.support@hoylu.com"
  }
};
