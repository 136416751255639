// Invite-admin selectors
import isEmpty from "lodash/isEmpty";
import { RootState } from "typesafe-actions";

export const isLoading = (state: RootState): boolean =>
  isEmpty(state.inviteAdmin) ? false : !!state.inviteAdmin.loading;
export const success = (state: RootState): boolean =>
  isEmpty(state.inviteAdmin) ? false : !!state.inviteAdmin.success;
export const error = (state: RootState): boolean =>
  isEmpty(state.inviteAdmin) ? false : !!state.inviteAdmin.error;
