import { useSelector } from "react-redux";
import { organizationSelectors } from "../../../state/organization";
import { AdAdministration } from "../../ad.management/ad.administration";
import { ADRequest } from "../../ad.management/ad.request";

export const ADManagement = (): JSX.Element => {
  const loginType = useSelector(organizationSelectors.loginType);

  const renderContent = () => {
    if (loginType !== "AzureAD") {
      return <ADRequest />;
    } else {
      return <AdAdministration />;
    }
  };

  return (
    <div className="container h-full flex flex-col">
      {renderContent()}
    </div>
  );
};
