import React from "react";

/**
 * Use specified `id` value or generate a unique id.
 * @param id
 * @returns specified or unique id value
 * @example
 * useId("foo") // "foo"
 * useId("") // ":r0:"
 * useId() // ":r0:"
 */
export const useId = (id?: string) => {
  const reactId = React.useId();
  // NOTE: no-sonar: intentionally want to include falsey empty string value in left-hand operand
  return id || reactId; // NOSONAR
};
